

























































import { Component, Prop } from "vue-property-decorator";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { mixins } from "vue-class-component";
import { AUTH_STORE_NAME } from "@/store/auth.store";
import { namespace } from "vuex-class";
import { APPLICATION_STORE_NAME, ApplicationStoreActions } from "@/store/application.store";
import { ApplicationStatus } from "@/enum/ApplicationStatus.enum";
import Application from "@/models/Application";
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required, requiredIf } from "vuelidate/lib/validators";
import { StatusEnum } from "@/enum/Status.enum";
import StatusJob from "@/models/status";
import ContractRepository from "@/api/repositories/ContractRepository";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  mixins: [validationMixin],
  validations: {
    easyCardId: { required, minLength: minLength(4), maxLength: maxLength(9) },
    mandatsReference: {
      required: requiredIf((vm) => {
        return !vm.isBankTransfer && (vm.mandate === undefined || vm.mandate.length <= 0);
      }),
      minLength: minLength(5),
      maxLength: maxLength(36)
    }
  }
})
export default class RequestAcceptedComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({ default: Application.parseFromObject({}) })
  public application!: Application;

  @Prop({ default: undefined })
  public mandate?: string | undefined;

  private checkJobs: string[] = [];

  @ApplicationStore.Action(ApplicationStoreActions.REQUEST_APPLICATION)
  private acceptApplication!: (payload: {
    applicationId: string,
    status: ApplicationStatus,
    easyCardId: string,
    mandatRef: string
  }) => Promise<any | null>;

  @ApplicationStore.Action(ApplicationStoreActions.GET_APPLICATIONS)
  private getApplication!: (payload: {
    status?: ApplicationStatus,
    overwrite?: boolean
  }) => Promise<any | null>;

  @ApplicationStore.Action(ApplicationStoreActions.CHECK_INFORMATION_JOB)
  private checkJobInformation!: (payload: {
    applicationId?: string
  }) => Promise<StatusJob | null>;

  @ApplicationStore.Action(ApplicationStoreActions.CHECK_JOBS)
  private removeJobs!: (payload: {
    job: Partial<StatusJob>
  }) => Promise<any | null>;

  private intervalId?: number;

  private status: StatusJob[] = [];


  private async onJobChanged(job: Partial<StatusJob>) {
    if (job.status == StatusEnum.FINISHED) {
      this.$notifySuccessSimplified("GENERAL.NOTIFICATIONS.JOB_SUCCESS");
      await this.removeJobFromList(job);

      const copie = [...this.checkJobs];
      const filter = copie.filter(v => v != job._id);
      this.checkJobs = filter;
    }
    if (job.status == StatusEnum.FAILED) {
      this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.JOB_FAILED");
      await this.removeJobFromList(job);

      const copie = [...this.checkJobs];
      const filter = copie.filter(v => v != job._id);
      this.checkJobs = filter;
    }

    if (this.checkJobs.length <= 0) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

  private easyCardId: string = "";

  private mandatsReference: string = "";

  public get isBankTransfer() {
    return this.application.user?.billingDetails?.usesBankTransfer === true || false;
  }

  private async removeJobFromList(status: Partial<StatusJob>) {
    await this.removeJobs({ job: status });
  }

  private validateJobs(changedApplication: Application) {
    if (!changedApplication) return;
    if (changedApplication.id != null) this.checkJobs.push(changedApplication.id);
    if (this.intervalId === undefined) {
      this.intervalId = setInterval(async () => {
        this.status = [];
        const list = await Promise.all(this.checkJobs.map
        (async (appId) =>
          await this.checkJobInformation({ applicationId: appId })));
        list.forEach(
          (value) => {
            this.onJobChanged({ ...value });
            this.status.push(value!);
          }
        );
      }, 20000);
    }
  }

  private async acceptRequest() {
    // Set Mandate if it is not undefined
    if (this.mandate != undefined && this.mandate != "") {
      this.mandatsReference = this.mandate;
    }

    // Trigger validation
    this.$v.$touch();

    // Form is Invalid return here
    if (this.$v.$invalid) {
      this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS");
      return;
    }

    try {
      if (this.application === undefined || this.application.id === undefined) return;
      this.isLoading = true;

      // Get contract id by removing the first char of easyCardId
      const contracts = await this.tryGetContractById(this.easyCardId);

      // There is a contract with the given id
      if (contracts.length > 0) {
        this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.CONTRACT_ID_ALREADY_EXISTS");
        this.isLoading = false;
        return;
      }

      // Accept Application via API
      await this.sendAcceptMessage();

      this.dismiss();
    } catch (e) {
      this.isLoading = false;
      this.$handleError(e, () => {
        switch (e.status) {
          case 424:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.MULTIPLE_ACCOUNTS_WITH_SAME_EMAIL");
            break;
          case 409:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.FAILED_TO_UPDATE_APPLICATION");
            break;
          case 423:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.APPLICATION_STATUS");
            break;
          case 412:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.EASY_CARD_EXISTS");
            break;
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.ERROR_GENERAL");
            break;
        }
      });
    }
  }

  /**
   * Search for Contracts with the given id
   * @param id - Contract Id
   * @private
   */
  private async tryGetContractById(id: string) {
    try {
      const res = await ContractRepository.searchContractsById(id);
      return res.data;
    } catch (e) {
      return [];
    }
  }

  private async sendAcceptMessage() {
    // Accept Application via API
    const acceptedRequest = await this.acceptApplication({
      applicationId: this.application.id!,
      status: ApplicationStatus.Accepted,
      easyCardId: this.easyCardId,
      mandatRef: this.mandatsReference
    });

    // Get the application again
    await this.getApplication({ overwrite: true });
    this.isLoading = false;

    // Notify the user and start the polling
    this.$notifySuccessSimplified("GENERAL.NOTIFICATIONS.ACCEPTED_APPLICATION");
    this.validateJobs(acceptedRequest);
  }

  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit("closeDialog");
  }

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;
}
